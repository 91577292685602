import React, { useState, useEffect } from 'react';
import logoSinFondo from '../../images/logosApp/logo_d_p.png';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = ({regresar = false, hideButtons = false }) => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`top-0 left-0 w-full transition-colors duration-300 ${scrolled ? 'fixed bg-[#412CC5] z-30' : 'bg-transparent'}`}>
      <div className="flex flex-wrap items-center justify-between bg-[#412CC5] p-4 shadow-lg">
        <div className="flex items-center justify-center w-full md:w-auto mb-4 md:mb-0">
          <img
            src={logoSinFondo}
            alt="Logo"
            className="h-12 w-auto md:h-16 md:w-auto mr-3 rounded-2xl"
          />
        </div>
        {!hideButtons && (
          <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 space-x-0 md:space-x-4 w-full md:w-auto">
            {regresar ? (
              <a
                className="text-white bg-[#F15A24] text-center px-4 py-2 font-bold rounded-lg transition-transform transform hover:scale-105 hover:bg-[#da5727] hover:shadow-lg shadow-md duration-300 flex items-center justify-center w-full md:w-auto"
                href='/'
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
                Regresar
              </a>
            ) : (
              <a
                className="text-white bg-[#F15A24] text-center px-4 py-2 font-bold rounded-lg transition-transform transform hover:scale-105 hover:bg-[#da5727] hover:shadow-lg shadow-md duration-300 flex items-center justify-center w-full md:w-auto"
                href='/from-mi-primer-delivery'
              >
                Solicita tu delivery
              </a>
            )}
            <a
              className="text-white bg-[#F15A24] px-4 py-2 text-center font-bold rounded-lg transition-transform transform hover:scale-105 hover:bg-[#da5727] hover:shadow-lg shadow-md duration-300 flex items-center justify-center w-full md:w-auto"
              href='https://app.deliveryplus.ec/'
            >
              Iniciar Sesión
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
