import apiImage from '../../images/api.png';
import React from "react";

const InformationApi = () => {

    return (
        <>
        <div className="bg-white text-black py-10 px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center"> 
            <div className="w-full lg:w-1/2 lg:pr-4 mt-5 px-4 sm:px-6"> 
                <h1 className="text-3xl sm:text-4xl font-extrabold text-center text-[#412CC5]"><b>Información de la API</b></h1>
                <h3 className="text-xl sm:text-2xl font-semibold text-left mt-8 text-[#F15A24] mb-4">¿Qué es una API?</h3>
                <p className="text-justify mb-6 text-gray-700 text-base sm:text-xl">
                    Una API se utiliza para permitir la integración con otros servicios y aplicaciones, 
                    facilitando la gestión de pedidos, envíos y la recolección de datos.
                </p>
            </div>
            <div className="flex justify-center w-full lg:w-1/2 px-5 mt-5"> 
                <img src={apiImage} alt="Imagen de API" className="max-w-xs sm:max-w-md lg:max-w-xl h-auto rounded-lg shadow-lg transition-transform transform hover:scale-105" />  
            </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center mb-10">
            <a
              className="text-white bg-[#F15A24] mr-6 mb-4 sm:mb-0 px-8 py-4 sm:px-10 sm:py-5 font-bold rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:bg-[#da5727] shadow-md hover:shadow-xl duration-300"
              href='https://fast-api-deverly-plus-344944643133.us-central1.run.app'
              target='_blank'
            >
              Ver API
            </a>
            <a
              className="text-white bg-[#F15A24] mr-6 mb-4 sm:mb-0 px-8 py-4 sm:px-10 sm:py-5 font-bold rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:bg-[#da5727] shadow-md hover:shadow-xl duration-300"
              href='https://fast-api-deverly-plus-344944643133.us-central1.run.app/documentation'
              target='_blank'
            >
               Documentación
            </a>
        </div>
        </>
    );
};

export default InformationApi;