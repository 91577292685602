import React, { useEffect } from "react";
import Navbar from "./home/NavBar";
import Footer from "./home/Footer";
import CarouselClients from "./home/CarouselClients";
import "../../css/Marketing.css";

const Marketing = () => {
  useEffect(() => {
    window.fbq = window.fbq || function () {
      window.fbq.callMethod ? window.fbq.callMethod.apply(window.fbq, arguments) : window.fbq.queue.push(arguments)
    };
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);

    script.onload = () => {
      window.fbq('init', '1035200728429126');
      window.fbq('track', 'PageView');
    };

    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.height = "1";
    img.width = "1";
    img.style.display = "none";
    img.src = "https://www.facebook.com/tr?id=1035200728429126&ev=PageView&noscript=1";
    noscript.appendChild(img);
    document.head.appendChild(noscript);
  }, []);

  return (
    <>
      <Navbar regresar={false} hideButtons={true} />
      <main className="px-6 py-12 bg-white">
        <h1 className="text-5xl font-bold text-center text-[#5a2f9f] mb-6">
          Aumenta tus ventas haciendo entregas a domicilio
        </h1>
        <h2 className="text-4xl font-semibold text-center text-[#5a2f9f] mb-16">
          Tenemos una flota de motorizados listos para ti
        </h2>
        <div className="text-center mb-16">
          <a
            href="https://wa.me/593995167942?text=Hola%20deseo%20conocer%20más%20información"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-[#F15A24] px-12 py-6 font-bold rounded-full inline-block border-2 border-white"
            style={{
              boxShadow: '0 0 25px 10px rgba(241, 90, 36, 0.7)',
              transition: 'all 0.3s ease',
              fontSize: '1.5rem'
            }}
          >
            Contactarme ahora
          </a>
        </div>
        <p className="text-center text-4xl text-[#5a2f9f] mb-4">
          Más de 300 compañías satisfechas
        </p>
        <div className="text-center mb-10">
          <div className="stars-container">
            <span className="star">★</span>
            <span className="star">★</span>
            <span className="star">★</span>
            <span className="star">★</span>
            <span className="star">★</span>
          </div>
        </div>

        {/* Carousel de Clientes */}
        <div className="my-12">
          <CarouselClients />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Marketing;
